import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import SEO from "../components/SEO"
import linkResolver from "../utils/linkResolver"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"
import { getFrontURL } from "../utils/utils"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

const CgvPage = ({ data, pageContext: { locale }, location }) => {
  const CgvPageData = data.prismic.cgvpage
  return (
    <>
      <SEO
        title={CgvPageData.meta_title}
        desc={CgvPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: CgvPageData.title,
          subtitle: CgvPageData.subtitle,
        }}
      />
      <Helmet>
        <script type="text/javascript">
          {`
            window.onmessage = (event) => {
              // Do we trust the sender of this message?
              if (event.origin === '${getFrontURL()}') {
                document.getElementById('header_main').style.display = 'none';
                document.getElementById('footer_main').style.display = 'none';
                document.getElementsByTagName('main')[0].style['margin-top'] = '0px';
              }
            };
          `}
        </script>
      </Helmet>
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-centered">
            <div className="column hasRichText is-10">
              {RichText.render(CgvPageData.richtext, linkResolver)}
            </div>
          </div>
        </div>
      </section>
      <ArrowScrollToTop />
    </>
  )
}

export default CgvPage

CgvPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const CgvQuery = graphql`
  query CgvQuery($uid: String!, $locale: String!) {
    prismic {
      cgvpage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
      }
    }

    site {
      ...SiteInformation
    }
  }
`
